<template>
  <div class="parentLevel">
    <div class="kind1">
      <el-table v-loading="loading" :data="tableData" border>
        <el-table-column prop="name" :label="$t('sysItem.name')">
          <template slot-scope="scope">
            <el-button style="width: 100%" :type="scope.row.no === showValue ? 'primary' : ''"
                       @click="clickTab(scope.row)">
              {{scope.row.name}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-table v-show="showValue.startsWith('00')" v-loading="loading2" :data="tableData2" class="kind2">
      <el-table-column prop="itemName" :label="$l('userTakeRecord.name', '名称')" width="230px">
      </el-table-column>
      <el-table-column prop="note" :label="$l('userTakeRecord.remark', '备注')" width="250px">
      </el-table-column>
      <el-table-column prop="value" :label="$l('userTakeRecord.value', '值')">
        <template v-slot="scope">
          <template v-if="scope.row.type === '0040' && scope.row.itemNo === '0007'">
            <span v-if="scope.row.value === '0'">{{$l("common.no", "否")}}</span>
            <span v-if="scope.row.value === '1'">{{$l("common.yes", "是")}}</span>
          </template>
          <span v-else>{{scope.row.value}}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="editAuth" :label="$t('common.operation')" align="center">
        <template v-slot="scope">
          <el-button
            v-if="scope.row.type === '0040' && scope.row.itemNo === '0007'"
            :type="scope.row.value === '0' ? 'success' : 'info'"
            @click="handleStatus(scope.row)">
            {{scope.row.value === "1" ? $t("sysItem.close") : $t("sysItem.open")}}
          </el-button>
          <el-button v-else @click="handleEdit(scope.row)">{{$t("common.edit")}}</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-table v-show="showValue.startsWith('11')" v-loading="loading2" :data="tableData2" class="kind2">
      <el-table-column prop="itemName" :label="$l('userTakeRecord.name', '名称')">
      </el-table-column>
      <el-table-column prop="note" :label="$l('userTakeRecord.remark', '备注')">
      </el-table-column>
      <el-table-column v-if="editAuth" :label="$t('sysItem.status')">
        <template slot-scope="scope">
          <el-button :type="scope.row.value === '0' ? 'success' : 'info'" @click="handleStatus(scope.row)">
            {{scope.row.value === "1" ? $t("sysItem.close") : $t("sysItem.open")}}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="showValue.startsWith('22')" class="kind3">
      <div style="padding-top: 20px">
        <el-button type="success" :loading="loadCacheDataLoading" @click="loadCacheData">
          重新初始化电梯缓存
        </el-button>
        <el-button type="success" :loading="createNextMaintOrderLoading" @click="createNextMaintOrder">
          超期工单自动生成下一条
        </el-button>
        <el-button type="success" :loading="deviceStateLoading" @click="deviceStatus">
          重新初始化在线缓存
        </el-button>
        <el-button type="success" :loading="videoUrlLoading" @click="updateLockFloorByBatch">
          重新执行批量执行锁梯任务
        </el-button>
      </div>
      <div style="padding-top: 20px">
        <el-button type="success" :loading="videoUrlLoading" @click="updateVideoUrlByBatch">
          重新执行从英旭获取视频链接（目前只有西尼在用）
        </el-button>
        <el-button type="success" :loading="videoRTCUrlLoading" @click="updateVideoRTCUrlByBatch">
          重新获取RTC视频链接（目前只有西尼在用）
        </el-button>
      </div>

    </div>
    <div v-show="showValue.startsWith('33')" style="margin: 0 auto; width: 600px;">
      <div v-loading="contentLoading" class="body">
        <el-form
          ref="formValidate"
          class="form"
          label-width="100px"
          :model="elevatorCallParam"
          style="padding-right: 20px; margin-top: 5vh;">
          <div class="vm-separate">
            <el-form-item label="是否启用">
              <el-switch v-model="elevatorCallParam.isApply" active-text="是" inactive-text="否">
              </el-switch>
            </el-form-item>
            <div></div>
          </div>
          <div class="vm-separate">
            <el-form-item label="呼梯距离(米)">
              <el-input v-model="elevatorCallParam.distance"></el-input>
            </el-form-item>
            <div></div>
          </div>
          <el-button
            type="primary"
            :loading="submitLoading"
            :disabled="saveDisabled"
            style="margin-left: 15%"
            @click="handleSubmit">
            {{$t("common.save")}}
          </el-button>
        </el-form>
      </div>
    </div>
    <div v-show="showValue.startsWith('44')" style="margin-left: 100px;">
      <maint-setting-list system-code="admin"></maint-setting-list>
    </div>
    <div v-show="showValue.startsWith('4001')" style="margin-left: 20px;width: 100%">
      <eocd-select></eocd-select>
    </div>
    <div v-show="showValue.startsWith('5555')" class="kind2">
      <fault-reason-list></fault-reason-list>
    </div>
    <div v-show="showValue.startsWith('6666')" style="margin: 0 auto; width: 600px;">
      <div v-loading="contentLoading" class="body">
        <el-form
          ref="form"
          class="form"
          label-width="100px"
          :model="rtcParam"
          style="padding-right: 20px; margin-top: 5vh;">
          <div class="vm-separate">
            <el-form-item label="appId">
              <el-input v-model="rtcParam.paramKeyValue"></el-input>
            </el-form-item>
            <div></div>
          </div>
          <el-button
            type="primary"
            :loading="submitLoading"
            style="margin-left: 15%"
            @click="handleRtcSubmit">
            {{$t("common.save")}}
          </el-button>
        </el-form>
      </div>
    </div>
    <edit-dialog ref="editPage" @save-success="getSysItemList"></edit-dialog>
  </div>
</template>
<script>
  import EditDialog from "./SysItemEdit.vue";
  import MaintSettingList from "@/views/newMaintWorkOrder/maintSetting/maintSettingList";
  import EocdSettingList from "@/views/elevator/EocdSettingList";
  import FaultReasonList from "../faultReason/FaultReasonList";
  import EocdSelect from "../elevator/EocdSelect";

  const moduleName = "sysItems";

  export default {
    components: {FaultReasonList, EocdSelect, MaintSettingList, EditDialog},
    data() {
      return {
        editAuth: this.$auth(41),
        videoRTCUrlLoading: false,
        loading: false,
        loading2: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        deviceStateLoading: false,
        videoUrlLoading: false,
        tableData: [],
        tableData2: [],
        elevatorCallParam: {
          isApply: true,
          distance: 0,
        },
        rtcParam: {
          id: 0,
          paramKeyValue: "",
        },
        showValue: "",
        dataType: "",
        doWuxiPollingLoading: false,
        loadCacheDataLoading: false,
        createNextMaintOrderLoading: false,
      };
    },
    mounted() {
      this.getList();
    },
    methods: {
      getList() {
        this.loading = true;
        this.$api.getList(moduleName).then(response => {
          this.loading = false;
          this.tableData = response.data;
          this.showValue = this.tableData[0].no;
          this.getSysItemList();
        }).catch(() => {
          this.loading = false;
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      getElevatorCallParam() {
        this.contentLoading = true;
        this.$api.getData("elevatorCallParam").then(response => {
          this.contentLoading = false;
          this.elevatorCallParam = response.data;
        }).catch(() => {
          this.contentLoading = false;
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      getRtcParam() {
        this.$http.get("sysConfig/webRtcKey").then(res => {
          this.rtcParam = res.data;
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      handleRtcSubmit() {
        if (!this.rtcParam.paramKeyValue) {
          this.$message.error("appId不能为空");
          return;
        }
        this.submitLoading = true;
        this.$http.save("sysConfig", this.rtcParam)
          .then(() => {
            this.submitLoading = false;
            this.$emit("save-success");
            this.$message.success(this.$t("common.tip.saveSuccess"));
          }).catch(() => {
          this.submitLoading = false;
        });
      },
      handleSubmit() {
        if (!this.elevatorCallParam.distance) {
          this.$message.error("呼梯距离不能为空");
          return;
        }
        this.submitLoading = true;
        this.$api.save("elevatorCallParam", this.elevatorCallParam).then(res => {
          this.submitLoading = false;
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
      getSysItemList() {
        this.loading2 = true;
        this.$api.getList("sysItems/type/" + this.showValue).then(response => {
          this.loading2 = false;
          this.tableData2 = response.data;
        }).catch(() => {
          this.loading2 = false;
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      handleStatus(row) {
        row.value = row.value === "0" ? "1" : "0";
        this.$axios.put("sysItems/" + row.id + "/value/" + row.value).then(res => {
          if (row.value === "1") {
            this.$message.success(this.$t("developer.tip.activateSucceed"));
          } else {
            this.$message.success(this.$t("developer.tip.closeSucceed"));
          }
        }).catch(() => {
          this.$message.error(this.$t("common.tip.operationFailure"));
        });
      },
      handleEdit(row) {
        this.$refs.editPage.open(row.id, this.dataType);
      },
      clickTab(row) {
        this.showValue = row.no;
        this.dataType = row.dataType;
        this.getSysItemList();
        if (this.showValue.startsWith("33")) {
          this.getElevatorCallParam();
        }
        if (this.showValue.startsWith("6666")) {
          this.getRtcParam();
        }
      },
      loadCacheData() {
        this.loadCacheDataLoading = true;
        this.$api.getData("sysItems/loadCacheData").then(() => {
          this.loadCacheDataLoading = false;
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch(() => {
          this.loadCacheDataLoading = false;
          this.$message.error(this.$t("common.tip.operationFailure"));
        });
      },
      deviceStatus() {
        this.deviceStateLoading = true;
        this.$api.getData("sysItems/deviceStatus").then(() => {
          this.deviceStateLoading = false;
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch(() => {
          this.deviceStateLoading = false;
          this.$message.error(this.$t("common.tip.operationFailure"));
        });
      },
      createNextMaintOrder() {
        this.createNextMaintOrderLoading = true;
        this.$api.getData("sysItems/createNextMaintOrder").then(() => {
          this.createNextMaintOrderLoading = false;
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch(() => {
          this.createNextMaintOrderLoading = false;
          this.$message.error(this.$t("common.tip.operationFailure"));
        });
      },
      updateVideoRTCUrlByBatch() {
        this.videoRTCUrlLoading = true;
        this.$api.getData("sysItems/updateRTCVideoUrl").then(() => {
          this.videoRTCUrlLoading = false;
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch(() => {
          this.videoRTCUrlLoading = false;
          this.$message.error(this.$t("common.tip.operationFailure"));
        });
      },
      updateVideoUrlByBatch() {
        this.videoUrlLoading = true;
        this.$api.getData("sysItems/updateVideoUrlByBatch").then(() => {
          this.videoUrlLoading = false;
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch(() => {
          this.videoUrlLoading = false;
          this.$message.error(this.$t("common.tip.operationFailure"));
        });
      },
      updateLockFloorByBatch() {
        this.videoUrlLoading = true;
        this.$api.getData("lockFloor/retryTaskOfLockFloor").then(() => {
          this.videoUrlLoading = false;
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch(() => {
          this.videoUrlLoading = false;
          this.$message.error(this.$t("common.tip.operationFailure"));
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
.parentLevel {
  display: flex;

  .kind1 {
    width: 200px;
    margin-bottom: 40px;
  }

  .kind2 {
    flex: 1;
    margin-left: 20px;
  }

  .kind3 {
    flex: 1;
    margin: 0 20px;
  }

  .el-table {
    border-radius: 12px;
  }
}
</style>
