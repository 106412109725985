<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1100px"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="150px"
        :model="model">
        <el-form-item label="编码" prop="code" :rules="$rule.notNull">
          <el-input v-model="model.code" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-divider content-position="left">中文</el-divider>
        <el-form-item label="异常原因" prop="reason" :rules="$rule.notNull">
          <el-input v-model="model.reason" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item label="异常详情" prop="details" :rules="$rule.notNull">
          <el-input v-model="model.details" rows="3" type="textarea" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>

        <el-divider content-position="left">English</el-divider>
        <el-form-item label="Reason" prop="reasonEn">
          <el-input v-model="model.reasonEn" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item label="Details" prop="detailsEn">
          <el-input v-model="model.detailsEn" rows="3" type="textarea" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-divider content-position="left">Italia</el-divider>
        <el-form-item label="Motivo" prop="reasonIt">
          <el-input v-model="model.reasonIt" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item label="Dettagli dell'eccezione" prop="detailsIt">
          <el-input v-model="model.detailsIt" rows="3" type="textarea" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onDialogClose">{{ $t("common.cancel") }}</el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit">
        {{ $t("common.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>


  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          id: 0,
          reason: "",
          details: "",
          reasonEn: "",
          detailsEn: "",
          reasonIt: "",
          detailsIt: "",
          code: ""
        },
      };
    },
    computed: {
      title() {
        return (this.model.id > 0 ? this.$t("common.edit") : this.$t("common.add"));
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.model.id = id;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.contentLoading = true;
        this.saveDisabled = true;
        this.$http.get("eocdException/getById/"+this.model.id).then(res => {
          this.model = res.data;
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },

      onDialogClose() {
        this.contentLoading = false;
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
        this.$refs.formValidate.resetFields();
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$api.save("eocdException", this.model).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success");
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped></style>
